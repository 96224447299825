import { TextField, Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

const Detail = ({ data, show, onClose }) => {
  return (
    <>
      <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm" // Cambia el tamaño máximo del diálogo a 'sm' (small)
        fullWidth // Hace que el diálogo use el ancho completo permitido
      >
        <DialogTitle id="form-dialog-title">Detalle</DialogTitle>

        <section
          style={{
            width: "80vw", // Ajusta a 80% del ancho de la ventana
            maxWidth: 600, // Limita el ancho máximo a 600px
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          Datos Enviados
          <TextField
            value={data.datosEnviados}
            size="medium"
            name="title"
            type="text"
            variant="outlined"
            multiline
            disabled
            style={{
              width: "100%",
              marginBottom: 20,
              minHeight: 100,
              maxHeight: 200,
              overflowY: "auto",
            }}
          />
          Datos Recibidos
          <TextField
            value={data.datosRecibidos}
            size="medium"
            name="title"
            type="text"
            variant="outlined"
            multiline
            disabled
            style={{
              width: "100%",
              marginBottom: 20,
              minHeight: 100,
              maxHeight: 200,
              overflowY: "auto",
            }}
          />
        </section>
        <DialogActions>
          <Button onClick={onClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default Detail;
