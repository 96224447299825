import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import moment from "moment";
import { useState } from "react";
import { Button, Dimmer, Grid, Loader } from "semantic-ui-react";
import "./IntegrationsAudit.css";
import DialogDetail from "./Detail";

export default function IntegrationsAudit({
  dataTable,
  quantityPages,
  page,
  loadingActive,
  handleChangePage
}) {
  const [dialogDetail, setDialogDetail] = useState({
    open: false,
    data: null,
  });

  const handleCloseDetail = () => {
    setDialogDetail({
      open: false,
      data: null,
    });
  };
  const handleOpenDetail = (data) => {
    setDialogDetail({
      open: true,
      data: data,
    });
  };

  const TableRowData = ({ item }) => {
    return (
      <TableRow colSpan={6}>
        <TableCell className="p-cell">
          {item.fecha ? moment(item.fecha).format("DD-MM-YYYY") : "Sin fecha"}
        </TableCell>
        <TableCell className="p-cell">
          {item.profesionalResponse?.apellido + " " + item.profesionalResponse?.nombre}
        </TableCell>
        <TableCell className="p-cell">{item.obraSocial?.nombre ?? "--"}</TableCell>
        <TableCell className="p-cell">{item.tipoAlerta?.nombre ?? "--"}</TableCell>
        <TableCell className="p-cell">{item.transaccionExitosa?.nombre ?? "--"}</TableCell>
        <TableCell className="p-cell">{item.numeroAutorizacion ?? "--"}</TableCell>
        <TableCell className="p-cell">
          <Button.Group>
            <IconButton
              className="btn-circle-blue"
              aria-label="search"
              onClick={() => handleOpenDetail(item)}
            >
              <Tooltip placement="top" title="Ver Detalle">
                <SearchIcon />
              </Tooltip>
            </IconButton>
          </Button.Group>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {loadingActive && (
        <Dimmer className="loader-fixed" active inverted>
          <Loader size="big">Cargando..</Loader>
        </Dimmer>
      )}

      {dataTable && dataTable.length > 0 && (
        <Grid.Row>
          <TableContainer component={Paper} id="color-letter-table-tasks">
            <Table aria-label="spanning table">
              <TableHead className="color-letter-inTable">
                <TableRow>
                  <TableCell className="letter-title-table not-padding-table-task">
                    <b>Fecha</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Profesional</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Obra Social</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Tipo Transacción</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Estado Transacción</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Número Autorización</b>
                  </TableCell>
                  <TableCell className="letter-title-table">
                    <b>Opciones </b>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dataTable.map((item) => (
                  <TableRowData item={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid.Row>
      )}

      <Grid.Row centered>
        {dataTable && dataTable.length > 0 && (
          <Pagination
            className="pagination"
            count={quantityPages}
            page={page}
            onChange={handleChangePage}
          />
        )}
      </Grid.Row>

      <Grid.Row centered>
        {dataTable && dataTable.length === 0 && (
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        )}
      </Grid.Row>

      {dialogDetail.data !== null && (
        <DialogDetail
          show={dialogDetail["open"]}
          onClose={handleCloseDetail}
          data={dialogDetail["data"]}
        />
      )}
    </>
  );
}
