import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useEffect, useState } from 'react';
import ReactSelect from "react-select";
import { es } from 'date-fns/locale';
import DateFnsUtils from "@date-io/date-fns";
import { Button, Card, Dimmer, Grid, Icon, Loader } from "semantic-ui-react";
import { CardContent, Paper, TableContainer } from '@material-ui/core';
import api from '../../api';
import { StatusCode, TypeRoleNum } from '../../enums';
import { GetProfesionals } from '../../services/services/Users';
import PeriodAuditList from './PeriodAuditList';
import TableCell from "@material-ui/core/TableCell";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';

const statusDocumentForSelect = [
  { value: 2, label: "No Verificado" },
  { value: 1, label: "Verificado" },
  { value: 3, label: "Facturado" }
];

const role = JSON.parse(localStorage.getItem('TypeRole'));

export default function PeriodAudit({ setDataToast }) {
  const locationUrl = useLocation();
  const history = useHistory();
  const [socialWorkList, setSocialWorks] = useState([]);
  const [professionalList, setProfessionals] = useState();
  const [selected, setSelected] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const [loadingSelect, setLoadingSelect] = useState({ socialWork: false, professional: false });
  const [filterObj, setFilterObj] = useState({
    socialWork: null,
    professional: null,
    period: null,
    state: null
  });
  const [withoutDataTable, setwithoutDataTable] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const { profesionalId, periodo } = useParams();
  //paginado
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState();
	const loadForPage = 20;
  const handleChange = (event, value) => {
    handleClickSearch(value);
	};

  useEffect(() => {
    if (periodo) {
      const defaultDate = parsePeriodoToDate(periodo);
      setFilterObj(prevState => ({ ...prevState, period: defaultDate }));
    }
  }, [periodo]);

  useEffect(() => {
    setLoadingSelect({ professional: true, socialWork: true });
    GetSocialWorkList();
    GetProfesionalList();
  }, [])

  //#region CALLS
  function loadData(nroPage = 1) {
    //si se envian el periodo por parametros, utilizo este.
    const date = !!periodo
      ? { mes: periodo.split('-')[0], anio: periodo.split('-')[1] }
      : { mes: filterObj.period.getMonth() + 1, anio: filterObj.period.getFullYear() };

    let body = {
      socialWorkId: filterObj.socialWork?.id ?? null,
      month: date.mes,
      year: date.anio,
      professionalId: filterObj.professional?.value ?? null,
      stateId: filterObj.state?.value ?? null,
      pagina: nroPage,
      cantidad: loadForPage
    };
    HandleGetAuditPeriods(body);
  };

  async function HandleGetAuditPeriods(body) {
    try {
      setLoadingActive(true);
      const { data } = await api.getAuditPeriods(body);
      if (data.resultados && data.resultados.length !== 0) {
        setwithoutDataTable(false);
        setDataTable(data.resultados);

        let count = data.cantidadPaginas;
				setTotalCount(count);
      } else {
        setwithoutDataTable(true);
        setDataTable([]);
      }
    } catch (err) {
      MessageNotification(err.response.data.errores, "error");
    } finally {
      setLoadingActive(false);
    }
  }

  async function GetProfesionalList() {
    try {
      const { status, data } = await GetProfesionals({ rolId: TypeRoleNum.Professional });
      if (status === 200) {
        const RSUserList = data?.results?.map((item) => ({
          value: item.id,
          label: `${item.apellido}, ${item.nombre}`,
        })) ?? [];
        setProfessionals(RSUserList);

        // Si viene el profesional por parámetros, lo setteo como valor por defecto
        if (profesionalId) {
          const defaultProfessional = RSUserList.find(prof => prof.value === Number(profesionalId));
          if (defaultProfessional) {
            setFilterObj(prevState => ({ ...prevState, professional: defaultProfessional }));
          }
        }
      }
    } catch (err) {
      MessageNotification(err.message, "error");
    } finally {
      setLoadingSelect(prevState => ({ ...prevState, professional: false }));
    }
  }
  async function GetSocialWorkList() {
    try {
      const { status, data } = await api.GetSocialWorkForSelect();
      if (status === StatusCode.Ok) {
        const socialWorkForList = data.map(item => {
          return { id: item.id, name: item.nombre }
        });
        setSocialWorks(socialWorkForList);
      }
    } catch (err) {
      MessageNotification(err.response.data.errores, "error");
    } finally {
      setLoadingSelect(prevState => ({ ...prevState, socialWork: false }));
    }
  }

  function reloadData() {
    handleClickSearch(page);
  };
  //#endregion

  //#region UTILS
  function IsFilterObjValid() {
    if (filterObj.socialWork === null || filterObj.socialWork === undefined) {
      MessageNotification("Debe seleccionar una Obra Social.", "error");
      return false;
    }
    if (filterObj.period === null || (filterObj.period === undefined && !periodo)) {
      MessageNotification("Debe seleccionar un Periodo", "error");
      return false;
    }

    return true;
  }
  // Función para convertir el periodo en un objeto Date
  const parsePeriodoToDate = (periodo) => {
    if (!periodo) return null;

    const [month, year] = periodo.split('-');
    return new Date(year, month - 1); // Los meses en JavaScript van de 0 (enero) a 11 (diciembre)
  };
  function MessageNotification(message, type) {
    setDataToast({
      active: true,
      message: message || "Acaba de ocurrir un error.",
      type: type,
    });
  }
  //#endregion

  const handleClickSearch = (nroPage = 1) => {
    if (IsFilterObjValid()) {
      setDataTable([]);
      setPage(nroPage);
      loadData(nroPage);
    }
  };

  const handleClickCancel = () => {
    const { devolutionSection } = locationUrl.state ? locationUrl.state : false;
    if (devolutionSection) {
      history.push("/devoluciones/lista");
    } else {
      history.push("/periodos");
    }
  };

  return (
    <>
      {loadingActive && (
        <Dimmer className='loader-fixed' active inverted>
          <Loader size='big'>Cargando..</Loader>
        </Dimmer>
      )}
      <Grid>
        <Grid.Row>
          <Card className='color-border-dash'>
            <CardContent className='center-responsive-filters'>
              <div style={{ display: 'block', marginTop: 15, marginBottom: 15 }}>
                <span style={{ fontSize: 27, textAlign: "left" }}>Auditoria Períodos</span>
              </div>
              <Grid.Row className='content-filter-list' style={{ justifyContent: 'space-between' }}>
                <Grid.Column width={14} style={{ display: 'flex' }}>
                  <ReactSelect
                    className="filter-input"
                    placeholder='Obra Social...'
                    isLoading={loadingSelect.socialWork}
                    options={socialWorkList}
                    isClearable={true}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(e) => {
                      setFilterObj(prevState => ({ ...prevState, socialWork: e }))
                    }}
                  />

                  <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk
                      className='size-date-picke-filter filter-input'
                      inputVariant='outlined'
                      name='month'
                      openTo='month'
                      clearable
                      views={['month', 'year']}
                      placeholder='Período'
                      value={filterObj.period}
                      onChange={(date) => {
                        setFilterObj(prevState => ({ ...prevState, period: date }));
                        // disparador(setFiltroFecha(date));
                      }}
                      disabled={periodo}
                    />
                  </MuiPickersUtilsProvider>

                  <ReactSelect
                    className="filter-input"
                    placeholder="Profesionales..."
                    options={professionalList}
                    isLoading={loadingSelect.professional}
                    isClearable={true}
                    value={filterObj.professional}
                    onChange={(e) => setFilterObj(prevState => ({ ...prevState, professional: e }))}
                    isDisabled={!!profesionalId}
                  />

                  <ReactSelect
                    className="filter-input"
                    placeholder="Estado..."
                    isClearable={true}
                    options={statusDocumentForSelect}
                    onChange={(e) => setFilterObj(prevState => ({ ...prevState, state: e }))}
                  />
                </Grid.Column>

                <Grid.Column width={2} textAlign="right">
                  <Button
                    // className='btn-search-filter'
                    primary
                    onClick={() => handleClickSearch(1)}
                    icon
                    labelPosition='left'
                  >
                    <Icon name='search' />
                    Buscar
                  </Button>
                  {profesionalId && periodo &&
                    <Button onClick={handleClickCancel}>Volver</Button>
                  }
                </Grid.Column>
              </Grid.Row>
            </CardContent>
          </Card>
        </Grid.Row>
        {dataTable && dataTable.length === 0 && withoutDataTable &&
          <Grid.Row style={{ textAlign: "-webkit-center" }}>
            <TableContainer component={Paper} id="color-letter-table-tasks">
              <TableCell>
                <h5>No se encontraron resultados</h5>
              </TableCell>
            </TableContainer>
          </Grid.Row>
        }
        {dataTable && dataTable.length > 0 &&
          <>
            <PeriodAuditList
              IsFilterObjValid={IsFilterObjValid}
              reloadData={reloadData}
              MessageNotification={MessageNotification}
              selected={selected}
              setSelected={setSelected}
              dataTable={dataTable}
              role={role}
              setLoadingActive={setLoadingActive}
            />
            <Grid.Row centered>
              <Pagination
                className='pagination'
                count={totalCount}
                page={page}
                onChange={handleChange}
              />
            </Grid.Row>
          </>
        }
      </Grid>
    </>
  )
}
