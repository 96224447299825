import CardContent from "@material-ui/core/CardContent";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Card, Dimmer, Grid, Loader } from "semantic-ui-react";
import api from "../../api";
import { StatusCode, TypeRole } from "../../enums";
import "./Period.css";
import PeriodNewEditList from "./PeriodNewEditList";

export default function PeriodNewEdit({ setDataToast }) {
  const locationUrl = useLocation();
  const [dataTable, setDataTable] = useState();
  const [role, setRole] = useState();
  const [returnsComment, setReturnsComment] = useState({
    id: null,
    patient: null,
    comment: null,
    showModal: false,
  });
  const [documentsRemove, setDocumentsRemove] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loadingActive, setLoadingActive] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  async function RemoveDocFromPeriod(documentId){
    try{
      const professionalId = localStorage.getItem("professionalId");
      let documentRemoveArray = documentsRemove ? [...documentsRemove] : [];
      let document = {
        documentoId: returnsComment.id === null ? documentId : returnsComment.id,
        comentario: returnsComment.comment
      }
      
      documentRemoveArray.push(document);
      setDocumentsRemove(documentRemoveArray); 
      setReturnsComment({...returnsComment,comment: null, showModal: false});

      const {status} = await api.editPeriod(+id, documentRemoveArray);
      if (status === StatusCode.Ok){
        MessageNotification("Documento devuelto correctamente.", "success");
        GetData([api.getDocumentByPeriodId(id, professionalId)])
      }
    }catch(err){
      MessageNotification(err.response.data.errores, "error");
    }
    finally{
      setLoadingActive(false);
    }
  };

  useEffect(() => {
    let roleLogged = JSON.parse(localStorage.getItem('TypeRole'));
    setRole(roleLogged);
    setLoadingActive(true);
    let promiseFunction = [];
    if (id) {
      let professionalId = null;
      if (roleLogged != TypeRole.Professional) {
        professionalId = localStorage.getItem("professionalId");
      }
      promiseFunction.push(api.getDocumentByPeriodId(id, professionalId));
    } else {
      let bodyPagination = {
        withPeriod: false,
        pagination: false,
        orderby: "-FirstSessionDate",
      };
      promiseFunction.push(api.getDocuments(bodyPagination));
    }
    GetData(promiseFunction);
  }, []);

const GetData = (promiseFunction)=>{
  Promise.all(promiseFunction)
      .then((resp) => {
        setDataTable(null);
        
        if (resp[0].data.resultados !== undefined) {
          setDataTable(resp[0].data.resultados);
        } else {
          var temp = [];
          resp[0].data.forEach(x => {
            x.documentDataResponses.forEach(y => temp.push(y));
          });
          setDataTable(temp);
        }
        setLoadingActive(false);
        
      })
      .catch((err) => {
        setLoadingActive(false);
        MessageNotification(err.response.data.errores, "error");
      });
}

  const reloadData = () => {
    const professionalId = localStorage.getItem("professionalId");
    setLoadingActive(true)
    GetData([api.getDocumentByPeriodId(id, professionalId)]);
  };

  const handleClickCancel = () => {
    const {devolutionSection} = locationUrl.state ? locationUrl.state : false;
    if (devolutionSection){
      history.push("/devoluciones/lista");
    }else{
      history.push("/periodos");
    }
  };

  const handleClickSubmit = () => {
    let itemSelected = [];
    if (selected.length > 0) {
      selected.forEach((item) => {
        if (item != undefined || item != null) {
          itemSelected.push(item);
        }
      });
    }
    if (itemSelected.length > 0 || id) {
      setLoadingActive(true);
      let body = {
           documentosId: itemSelected,
         };

      api.showDocumentPeriod(body)
        .then((resp) => {
          setLoadingActive(false);
          MessageNotification(id ? "Presentación editada con éxito" : "Presentación realizada con éxito", "success");
          history.goBack();
        })
        .catch((err) => {
          setLoadingActive(false);
          MessageNotification(err.response.data.errores, "error");
        });
    }
  };
  function MessageNotification(message, type){
    setDataToast({
      active: true,
      message: message || "Acaba de ocurrir un error",
      type: type,
    });
  }
  const handleCloseOpenModalComment = ()=>{
    setReturnsComment({
      ...returnsComment,
      showModal: false
    });
  }
  return (
    <>
        <Dialog
        open={returnsComment.showModal}
        onClose={handleCloseOpenModalComment}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title' style={{textAlign:"center"}}>Desea desasociar del período el paciente, "{returnsComment.patient}" ?</DialogTitle>
        <DialogContent>
          <TextareaAutosize  
          className="commentReturn"
          style={{width: "100%"}}
          aria-label="minimum height" 
          minRows={4} 
          placeholder="Agregue un comentario..."  
          value={returnsComment.comment}
          onChange={(e) => {
            setReturnsComment({
              ...returnsComment,
              comment: e.target.value,
            });
          }}/>
        </DialogContent>

        <DialogActions
          style={{
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Button onClick={handleCloseOpenModalComment}>
            Cancelar
          </Button>
          <Button onClick={()=>RemoveDocFromPeriod()} color='blue'>
            Aceptar
          </Button>
        </DialogActions>
        </Dialog>
      <Grid>
        <Grid.Row>
          <Card className="color-border-dash">
            <CardContent className="center-responsive-filters">
              <Grid.Row style={{ marginBottom: 30 }}>
                <span style={{ fontSize: 27 }}>
                  {!id ? "Presentar Períodos" : "Editar Período"}
                </span>
              </Grid.Row>
              <Grid.Row style={{ textAlign: "end" }}>
                <Button onClick={handleClickCancel}>Volver</Button>
                {role === TypeRole.Professional &&
                <Button
                  onClick={handleClickSubmit}
                  disabled={!id ? selected.length === 0 : documentsRemove.length === 0}
                  primary
                >
                  {!id ? "Presentar" : "Guardar"}
                </Button>}
              </Grid.Row>
            </CardContent>
          </Card>
        </Grid.Row>
        {loadingActive ? (
          <Dimmer className="loader-fixed" active inverted>
            <Loader size="big">Cargando..</Loader>
          </Dimmer>
        ) : (
          dataTable &&
          dataTable.length > 0 && (
            <PeriodNewEditList
              returnsComment={returnsComment}
              setReturnsComment={setReturnsComment}
              reloadData={reloadData}
              MessageNotification={MessageNotification}
              RemoveDocFromPeriod={RemoveDocFromPeriod}
              periodId={id}
              selected={selected}
              setSelected={setSelected}
              dataTable={dataTable}
              role={role}
            />
          )
        )}
      </Grid>
    </>
  );
}
