import { Grid, Paper, TableContainer } from '@material-ui/core';
import Pagination from "@material-ui/lab/Pagination";
import React from 'react';
import { IGeneralSimpleList } from '../../services/interfaces/utils/IGeneralSimpleList';
import { Dimmer, Loader } from 'semantic-ui-react';

export const GeneralSimpleList: React.FC<IGeneralSimpleList> = ({
    dataToRender,
    children,
    paginated,
    isLoaderActive,
    initialPage,
    numPagesToRender,
    hasMaxHeight=false,
    onChangePage})=>{

    return(
    <Grid>
        
        {isLoaderActive ?
            <Dimmer className="loader-fixed" active inverted>
                <Loader size="big">Cargando...</Loader>
            </Dimmer>
        :
        <Grid container>
            <TableContainer component={Paper} id='color-letter-table-tasks' style={{maxHeight: hasMaxHeight ? "320px" : "initial"}}>
                {children}
            </TableContainer>
        </Grid>
        }
        
        {dataToRender && dataToRender.length === 0 &&
            <Grid>
                <TableContainer component={Paper} id='color-letter-table-tasks' style={{padding: 14}}>
                        <h5 style={{textAlign: "center"}}>No se encontraron resultados.</h5>
                </TableContainer>
            </Grid>
        }

        {paginated && 
            <Grid container justifyContent='center' style={{marginTop: 10}}>
                <Pagination
                    className="pagination"
                    count={numPagesToRender}
                    page={initialPage}
                    onChange={onChangePage}
                />
            </Grid>
        }
    </Grid>
    )
}

export default GeneralSimpleList;