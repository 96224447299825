import DateFnsUtils from "@date-io/date-fns";
import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core";
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { es } from 'date-fns/locale';
import React from 'react';
import { IPracticeExtended, PracticeAmountRowRequest } from "../../../../../api/Interfaces/configurations/IAmounts";
import { StatusCode } from "../../../../../enums";
import { IAssignAmountToPractice, IErrorNotification, IPracticeCheck, IPracticeSelected } from "../../../../../services/interfaces/Configurations/AssignAmountToPractice";
import { AssingAmountForPractices } from "../../../../../services/services/Amounts";
import { GetPractices } from "../../../../../services/services/Practices";
import GeneralModalCrud from '../../../../utils/GeneralModalCrud';
import GeneralSimpleList from "../../../../utils/GeneralSimpleList";
import { _DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE } from "../../defaultValues";
import ReactSelect from 'react-select';
import api from "../../../../../api";

const defaultValueSelectPlan = { id: null, nombre: null };

const AssignAmountToPractice: React.FC<IAssignAmountToPractice> = ({
	socialWorkId,
	openModal,
	modalOpened,
	setLoadingActive,
	HandleClearAllModalData,
	NotificationMessage,
	ReloadData
}) => {
	const [dateToAssign, setDateToAssign] = React.useState<Date | null>(null);
	const [practiceSelected, setPracticeSelected] = React.useState<IPracticeSelected>({});
	const [practiceList, setPracticeList] = React.useState<IPracticeExtended[]>([]);
	const [errorNotification, setErrorNotification] = React.useState<IErrorNotification>(_DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE._ERROR_NOTIFICATION);
	const [plansList, setPlansList] = React.useState([{}]);
	const [selectPlan, setSelectPlan] = React.useState(defaultValueSelectPlan);

	React.useEffect(() => {
		(openModal && GetSocialWorksPlan());
	}, [openModal]);

	React.useEffect(() => {
		if (selectPlan.id) {
			HandleGetPracticeList()
		}
	}, [selectPlan]);

	//#region GETPRACTICELIST / HandleGetPracticesBySocialWork / GetSocialWorksPlan / CREATE
	async function HandleGetPracticeList() {
		try {
			setLoadingActive(true);
			let { data, status } = await GetPractices(socialWorkId, Number(selectPlan.id), true, false, false, 1, 20, true);
			if (status === StatusCode.Ok) {
				const practiceList: IPracticeSelected = data.results.reduce((acc, actualValue) => {
					return {
						...acc,
						[actualValue.practicaObraSocialCuentaId]: {
							idPractice: +actualValue.practicaObraSocialCuentaId,
							checked: false,
							amount: 0
						}
					}
				}, {});
				setPracticeList(data.results);
				setPracticeSelected(practiceList);
			}
		} catch (err: any) {
			NotificationMessage("error", err.response.data.errores);
		} finally {
			setLoadingActive(false);
		}
	}

	async function GetSocialWorksPlan() {
		try {
			setLoadingActive(true);
			const plansData = await api.getSocialWorksPlan(+socialWorkId);

			let planList = plansData.data.map(plan => {
				return { value: plan.id, label: plan["nombre"] }
			});

			setPlansList(planList)
		}
		catch (err: any) {
			NotificationMessage('error', err.response.data.errores ?? "Ocurrió un error.");
		}
		finally {
			setLoadingActive(false);
		}
	}
	async function HandleSubmitData() {
		try {
			if (ValidateValues()) {
				setLoadingActive(true);
				const practicesSelected: IPracticeCheck[] = Object.values(practiceSelected).filter((practice: IPracticeCheck) => practice.checked);
				const request: PracticeAmountRowRequest[] = practicesSelected.map((practice: IPracticeCheck) => {
					const dateWitOutHours = dateToAssign as Date;
					dateWitOutHours.setHours(0, 0, 0, 0);
					return {
						id: practice.idPractice,
						fecha: dateWitOutHours,
						monto: practice.amount,
					}
				})

				const { data, status } = await AssingAmountForPractices(+socialWorkId, Number(selectPlan.id), request);
				if (status === StatusCode.Ok) {
					HandleCloseModalAssign();
					NotificationMessage("success", "Monto/s asignado/s correctamente.");
					ReloadData();
				}
			}
		} catch (err: any) {
			NotificationMessage("error", err.message ?? err.message);
		} finally {
			setLoadingActive(false);
		}
	}
	//#endregion

	//#region UTILS
	function ValidateValues() {
		let isValidated = true;
		let objError: IErrorNotification = _DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE._ERROR_NOTIFICATION;
		const practiceSelectedList: IPracticeCheck[] = Object.values(practiceSelected);
		const areValueSelected = practiceSelectedList.every((practice: IPracticeCheck) => practice.checked === false);
		const valueSelectedWithZero = practiceSelectedList.filter((practice: IPracticeCheck) => practice.checked).some((practice: IPracticeCheck) => practice.amount <= 0 || practice.amount > 9999999);

		if (areValueSelected || dateToAssign === null) {
			NotificationMessage("error", "Deberá seleccionar una fecha y al menos una práctica.");
			isValidated = false;
			if (dateToAssign === null) {
				objError = {
					...objError,
					date: true
				}
				setErrorNotification(objError);
			}
			if (areValueSelected) {
				practiceSelectedList.forEach((practice: IPracticeCheck) => {
					objError = {
						...objError,
						[practice.idPractice]: true
					}
				});
				setErrorNotification(objError);
			}
		} else {
			if (valueSelectedWithZero) {
				NotificationMessage("error", "Los montos asignados deberán ser mayores a cero y menores a 9.999.999.");
				isValidated = false;
				practiceSelectedList.forEach((practice: IPracticeCheck) => {
					if (practice.checked && (practice.amount <= 0 || practice.amount > 9999999)) {
						objError = {
							...objError,
							[practice.idPractice]: true
						}
					}
				});
				setErrorNotification(objError);
			}
		}
		objError = _DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE._ERROR_NOTIFICATION;
		return isValidated;
	}
	function HandleCheckAllPractices(e) {
		const allPracticeChecked: IPracticeCheck | {} = practiceList.reduce((acc, actualValue) => {
			return {
				...acc,
				[actualValue.practicaObraSocialCuentaId]: {
					...practiceSelected[actualValue.practicaObraSocialCuentaId],
					checked: e.target.checked,
					amount: e.target.checked ? +practiceSelected[actualValue.practicaObraSocialCuentaId]?.amount : 0
				}
			}
		}, {});
		setPracticeSelected(allPracticeChecked);
	}
	function HandleSelectDate(dateSelected) {
		setDateToAssign(dateSelected);
	}
	function HandleAssignAmount(e) {
		setPracticeSelected({
			...practiceSelected,
			[e.target.name]: {
				...practiceSelected[e.target.name],
				amount: +e.target.value
			}
		});
	}
	function HandleSelectPractice(e) {
		setPracticeSelected({
			...practiceSelected,
			[e.target.name]: {
				idPractice: +e.target.name,
				checked: e.target.checked,
				amount: e.target.checked ? +practiceSelected[e.target.name]?.amount : 0
			}
		})
	}
	function HandleCloseModalAssign() {
		setErrorNotification(_DEFAULT_VALUES_ASSIGN_AMOUNT_TO_PRACTICE._ERROR_NOTIFICATION);
		setDateToAssign(null);
		setPracticeSelected({});
		HandleClearAllModalData(modalOpened);
		setSelectPlan(defaultValueSelectPlan)
	}
	//#endregion

	return (
		<GeneralModalCrud
			openModal={openModal}
			titleOfModal="Asignar"
			HandleCloseModal={HandleCloseModalAssign}
			HandleSubmit={HandleSubmitData}
			styles="cont-default-modal"
		>
			<MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
				<KeyboardDatePicker
					style={{ margin: "auto", marginTop: 5, minWidth: "280px" }}
					autoOk
					className='size-date-picke-filter'
					inputVariant='outlined'
					InputAdornmentProps={{ position: 'end' }}
					variant='inline'
					name='month'
					error={errorNotification.date}
					format="dd/MM/yyyy"
					placeholder='Fecha'
					value={dateToAssign}
					onChange={(date) => HandleSelectDate(date)}
				/>
			</MuiPickersUtilsProvider>

			<div style={{ position: "relative", margin: "auto", marginTop: 10, minWidth: "280px" }}>
				<label htmlFor="my-select" className="customLabelSelect">Planes:</label>
				<ReactSelect
					placeholder="Planes..."
					options={plansList}
					onChange={(e: any) => setSelectPlan(prevState => ({ ...prevState, id: e?.value, nombre: e?.label }))}
					styles={{
						control: (s: any) => ({ ...s, maxWidth: 300 })
					}}
				/>
			</div>

			{selectPlan.id && dateToAssign &&
				<GeneralSimpleList
					dataToRender={practiceList}
					isLoaderActive={false}
					paginated={false}
					hasMaxHeight={true}
				>
					<Table aria-label='spanning table'>
						<TableHead>
							<TableRow>
								<TableCell className='letter-title-table c-text-center'>
									<Checkbox
										checked={Object.values(practiceSelected).length > 0 && Object.values(practiceSelected).every((practice: IPracticeCheck) => practice.checked)}
										onChange={(e) => HandleCheckAllPractices(e)}
									/>
								</TableCell>
								<TableCell className='letter-title-table c-text-center'>
									<b>Código - Práctica</b>
								</TableCell>
								<TableCell className='letter-title-table c-text-center'>
									<b>Monto</b>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{practiceList.map((item: IPracticeExtended) => (
								<TableRow key={item.practicaObraSocialCuentaId}>
									<TableCell className='letter-title-table c-text-center p-cell'>
										<Checkbox
											checked={practiceSelected[item.practicaObraSocialCuentaId]?.checked ?? false}
											name={`${item.practicaObraSocialCuentaId}`}
											onChange={(e) => HandleSelectPractice(e)}
										/>
									</TableCell>
									<TableCell className='letter-title-table c-text-center p-cell'>
										{item.codigo ?? "No asignado"} - {item.alias ?? item.nombre}
									</TableCell>
									<TableCell className='letter-title-table c-text-center p-cell'>
										<TextField
											value={practiceSelected[item.practicaObraSocialCuentaId]?.amount ?? 0}
											type='number'
											size="small"
											disabled={practiceSelected[item.practicaObraSocialCuentaId]?.checked === false || !practiceSelected[item.practicaObraSocialCuentaId]}
											name={`${item.practicaObraSocialCuentaId}`}
											error={errorNotification[item.practicaObraSocialCuentaId]}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => HandleAssignAmount(e)}
											InputProps={{
												inputProps: { max: 9999999, min: 0 },
												startAdornment: (
													<InputAdornment position="start">
														<AttachMoneyOutlinedIcon />
													</InputAdornment>
												),
											}}
										/>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</GeneralSimpleList>}
		</GeneralModalCrud>
	);
}

export default AssignAmountToPractice;