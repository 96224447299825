import api from "../../api";
import { IApiResponse } from "../../api/Interfaces/IApiResponse";
import { IPracticeExtended } from "../../api/Interfaces/configurations/IAmounts";
import errorHandler from "../errorHandler";

const GetPractices = async (socialWorkId: number, socialWorkPlanId: number, assign: boolean, withPaginated: boolean, removed?: boolean, page?: number, loadPage?: number, whitAsignedPlan?: boolean): Promise<IApiResponse<IPracticeExtended[]>> => {
    try{
        const response = await api.getPracticesBySocialWorkAndPlan(socialWorkId, socialWorkPlanId, assign, removed, withPaginated, page, loadPage, whitAsignedPlan);
        return {
            data: {results: response.data.resultados, quantity: response.data.cantidadPaginas},
            message: response.statusText,
            status: response.status
        }
    }catch(err){
       return errorHandler(err);
    }
}

export {GetPractices}